import { Panel, TextTitle } from 'components/common/styled-components-common';
import { ClaimNotFoundStyled } from './Claim.styled';
import { If, Then } from 'components/common/if-condition';
import { Else, ElseIf } from 'components/common/if-condition/if-condition';
import Button from 'components/common/button';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import ClaimCancelTask from 'modules/conditional/claim-cancel-task';
import WorkplazeThemeHideElement from 'components/elements/workplaze-theme-hide-element';
import { MenuBox, Navigation } from 'components/common/navigation';
import { useTranslation } from 'react-i18next';
import usePageContext from 'hooks/usePageContext';
import { useNavigate } from 'react-router-dom';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { CONTACT_NUMBER_WITH_THEME, TASK_REJECT_TASK_STATUS_CODE_LIST } from 'constants/default-config';
import { useEffect, useState } from 'react';
import useServiceConfig from 'hooks/useServiceConfig';
import useQueryParams from 'hooks/useQueryParams';
import useAuth from 'hooks/useAuth';
import { DEFAULT_PHARMCARE_PROGRAM } from 'constants/service-config';
import { POST } from 'api';
import EXPPopupJs from 'components/common/exp-popupjs';
import moment from 'moment';
import type { ClaimCheckCoveragePayload, TaskAlreadyDetail } from 'types';

function ClaimNotFoundV2() {
    const { t } = useTranslation(['claim', 'navigation']);
    const { setPageLoaded } = usePageContext();
    const navigate = useNavigate();
    const { profile, getProfile } = useAuth();
    const theme = useThemeWithPartner();
    const serviceConfig = useServiceConfig();
    const { query: params, queryToParams } = useQueryParams();
    //
    const [showCancel, setShowCancel] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [latestEffectiveDate, setLatestEffectiveDate] = useState<string | null>(null);
    const [companyCode, setCompanyCode] = useState<string | null>(null);
    const [taskAlreadyDetail, setTaskAlreadyDetail] = useState<TaskAlreadyDetail>({
        taskId: '',
        processCode: 0,
    });
    //
    const getProcessCode = (taskStatusCode = 0) => {
        if (taskStatusCode === 0) return 4;

        if (taskStatusCode === 10) return 14;

        if (taskStatusCode === 20) return 24;

        return 0;
    };
    const onSelectCoverage = async (coverageItem = {}, userPid: string | null = null) => {
        const { pharmacyCode, ltcFlag, hspCode } = params || {};

        try {
            const res = await POST('/claim/selectCoverage', {
                pid: userPid,
                taskCommunicationType: serviceConfig.communicationType,
                latitude: 0,
                longitude: 0,
                ltcFlag: ltcFlag,
                data: coverageItem,
                pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
            });

            let nextParams = `?task=${res.task.taskId}&flag=${res.task.phrExistFlag}`;
            if (pharmacyCode) {
                nextParams += `&pharmacyCode=${pharmacyCode}`;
            }
            if (hspCode) {
                nextParams += `&hspCode=${hspCode}`;
            }

            if (res.statusCode === 300) {
                setIsLoading(false);
                setTaskAlreadyDetail({
                    taskId: res.task.taskId,
                    processCode: getProcessCode(res.task.taskStatusCode),
                });

                //popup ทำรายการต่อหรือยกเลิก
                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:warning'),
                    message: res.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('claim:cancel_task.complete_ongoing_service'),
                            variant: 'primary',
                            action: () => navigate(`${res.task.ctaRedirectUrl}?task=${res.task.taskId}&flag=${res.task.phrExistFlag}`),
                        },
                        {
                            label: t('claim:cancel_task.cancel_ongoing_service'),
                            className: 'btn-underline',
                            action: () => setShowCancel(true),
                        },
                    ],
                });
            } else if (serviceConfig.name === 'telemed') {
                navigate(serviceConfig.servicePath + '/consult/select-department' + nextParams);
            } else if (serviceConfig.name === 'tlm') {
                navigate(serviceConfig.servicePath + '/select-hsp' + nextParams);
            } else if (pharmacyCode) {
                navigate(serviceConfig.servicePath + '/address' + nextParams);
            } else if (res.task.phrExistFlag === 1 || moment(res.task.createdAt).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                navigate(serviceConfig.servicePath + '/phr/existing' + nextParams);
            } else {
                navigate(serviceConfig.servicePath + '/phr/new/disease' + nextParams);
            }
        } catch (error: any) {
            setIsLoading(false);

            EXPPopupJs.simple('warning', t('common:error'), error.rejectReason);
        }
    };
    const checkCoverage = async () => {
        setIsLoading(true);

        let voucherCode = params.voucherCode ?? null;

        if (theme.name === 'workplaze' || companyCode === '13') {
            voucherCode = 'humatrix01';
        }
        if (theme.name === 'default_sunday' || companyCode === '01') {
            voucherCode = 'sunday01';
        }
        if (theme.name === 'default_oceanopd' || companyCode === '19') {
            voucherCode = 'oceanopd01';
        }
        if (theme.name === 'mtl' || companyCode === '33') {
            voucherCode = 'mtl01';
        }

        let payload: ClaimCheckCoveragePayload = {
            companyCode: companyCode,
            policyNumber: profile.policyNumber as string,
            voucherType: null,
            voucherCode: voucherCode,
            pharmcareProgram: DEFAULT_PHARMCARE_PROGRAM[serviceConfig.name],
            apiCallType: null,
            validatePid: 0,
            data: {
                firstName: profile.firstName as string,
                lastName: profile.lastName as string,
                userPidType: profile.userPidType as string,
                userPid: profile.userPid as string,
                userEmail: profile.userEmail as string,
                dateOfBirth: profile.dateOfBirth as string,
                phoneNumber: profile.phoneNumber as string,
            },
        };
        try {
            const res = await POST('/claim/checkCoverage', payload);
            if (res.statusCode === 200) {
                setLatestEffectiveDate(res.latestEffectiveDate);

                onSelectCoverage(res.data[0], payload.data.userPid);
            } else if (res.statusCode === 300) {
                setIsLoading(false);

                EXPPopupJs.alert({
                    icon: 'warning',
                    title: t('common:warning'),
                    message: res.rejectReason,
                    classNames: { message: 'font-detail2' },
                    buttons: [
                        {
                            label: t('claim:cancel_task.complete_ongoing_service'),
                            variant: 'primary',
                            action: () => navigate(`${res.task.ctaRedirectUrl}?task=${res.task.taskId}&flag=${res.task.phrExistFlag}`),
                        },
                        {
                            label: t('claim:cancel_task.cancel_ongoing_service'),
                            className: 'btn-underline',
                            action: () => setShowCancel(true),
                        },
                    ],
                });
            }
        } catch (error: any) {
            setIsLoading(false);

            EXPPopupJs.simple('warning', t('common:error'), error.rejectReason);
        }
    };

    const onCancel = async (res: any) => {
        setShowCancel(false);

        if (
            TASK_REJECT_TASK_STATUS_CODE_LIST.some((e) => e === res.data.task.taskStatusCode) &&
            serviceConfig.servicePath === '/telepharmacy'
        ) {
            navigate('/telepharmacy/service/task-reject?task=' + res.data.task.taskId);
        } else {
            navigate('/home');
        }
    };

    useEffect(() => {
        const init = async () => {
            let companyCodeTemp = params.companyCode ?? null;

            if (params.companyCode === '03') {
                companyCodeTemp = '00';
            }

            try {
                const userProfile = await getProfile();
                // can override companyCodeTemp
                if (userProfile.userXPartner) {
                    companyCodeTemp = userProfile.userXPartner.partnerCompanyCode;
                }
            } catch (error) {}

            setCompanyCode(companyCodeTemp);
            setPageLoaded(true);
        };

        init();
    }, []);

    return (
        <>
            <ClaimNotFoundStyled>
                <Panel className='custom-panel'>
                    <div className='image-frame'>
                        <img src='/images/common/claim-not-found.png' alt='claim-not-found' />
                    </div>
                    <If condition={theme.name === 'default_sunday' || companyCode === '01'}>
                        <Then>
                            <TextTitle style={{ justifyContent: 'center' }}>
                                {t('claim:not_found.unfortunately_OPD_coverage_insufficient_service')}
                            </TextTitle>
                            <div>โปรดเช็คอีกครั้งว่าคุณได้กรอกเลขกรมธรรม์ถูกต้อง โดยจะต้องมีเครื่องหมาย "-" คั่น</div>
                        </Then>
                        <ElseIf condition={theme.name === 'mtl'}>
                            <TextTitle style={{ justifyContent: 'center' }}>
                                {t('claim:not_found.you_are_not_eligible_under_insurance_coverage')}
                            </TextTitle>
                        </ElseIf>
                        <ElseIf condition={latestEffectiveDate === null}>
                            <TextTitle style={{ justifyContent: 'center' }}>
                                {t('claim:not_found.unfortunately_not_eligible_for_free_service')}
                            </TextTitle>
                        </ElseIf>
                        <Else>
                            <TextTitle style={{ justifyContent: 'center' }}>{t('claim:not_found.can_use_eligible_from')}</TextTitle>
                            <div className='my-3'>{latestEffectiveDate}</div>
                            <div className='mb-3'>{t('claim:not_found.if_not_cancel')}</div>
                        </Else>
                    </If>
                    {window.location.pathname !== '/claim/not-found' && (
                        <>
                            {/* <a href="/home" className="theme-btn theme-btn-primary w-75">ดูบริการอื่นๆ</a> */}
                            {theme.name === 'default_sunday' && (
                                <div>
                                    <Button
                                        type='button'
                                        variant={'primary'}
                                        className='w-100 maw-250 mt-3'
                                        disabled={isLoading}
                                        onClick={() => navigate(serviceConfig.servicePath + '/claim/pid' + window.location.search)}
                                    >
                                        กลับไปตรวจสอบสิทธิ์ใหม่
                                    </Button>
                                </div>
                            )}
                            <Button
                                type='button'
                                variant={theme.name === 'default_sunday' ? 'danger' : 'primary'}
                                className='w-100 maw-250 my-3'
                                loading={isLoading}
                                data-test-id='continueService'
                                onClick={checkCoverage}
                            >
                                {theme.name === 'mtl'
                                    ? t('claim:not_found.proceed_to_service_under_self_pay')
                                    : t('claim:not_found.use_without_coverage')}
                            </Button>
                        </>
                    )}
                    {(['mtl', 'default_oceanopd', 'default_sunday', 'rabbit'].some((s) => theme.name === s) ||
                        ['01', '19', '33'].some((s) => companyCode === s)) && (
                        <p>
                            {t([
                                `claim:not_found.please_contact.withTheme.${companyCode ? companyCode : theme.name}`,
                                'claim:not_found.please_contact.default',
                            ])}{' '}
                            <a
                                href={
                                    'tel:' + CONTACT_NUMBER_WITH_THEME[companyCode] ||
                                    CONTACT_NUMBER_WITH_THEME[theme.name] ||
                                    CONTACT_NUMBER_WITH_THEME.default
                                }
                                target='_blank'
                                className='text-primary fw-600'
                            >
                                <IconConfigWithTheme pageName='claim_not_found' iconName='contact' />{' '}
                                {CONTACT_NUMBER_WITH_THEME[companyCode] ||
                                    CONTACT_NUMBER_WITH_THEME[theme.name] ||
                                    CONTACT_NUMBER_WITH_THEME.default}
                            </a>{' '}
                            {(theme.name === 'default_sunday' || companyCode === '01') && (
                                <>
                                    {t('common:or')}{' '}
                                    <a
                                        className='text-primary fw-600'
                                        href={'https://liff.line.me/1645278921-kWRPP32q/?accountId=easysunday'}
                                        target='_blank'
                                    >
                                        @easysunday
                                    </a>{' '}
                                </>
                            )}
                            {t('claim:not_found.for_customer_support')}
                        </p>
                    )}
                </Panel>
            </ClaimNotFoundStyled>
            <ClaimCancelTask
                open={showCancel}
                serviceOrigin={serviceConfig.serviceOrigin}
                taskDetail={taskAlreadyDetail}
                onSubmited={onCancel as any}
                onClose={() => setShowCancel(false)}
            />
            <WorkplazeThemeHideElement>
                <Navigation>
                    <MenuBox
                        to='/home'
                        tag='/home'
                        title={t('navigation:home')}
                        icon={<IconConfigWithTheme pageName='navigation' iconName='home' />}
                    />
                    {!['mtl', 'default_oceanopd'].some((f) => f === theme.name) && (
                        <MenuBox
                            to='/phr/existing'
                            tag='/phr'
                            title={t('navigation:health_record')}
                            icon={<IconConfigWithTheme pageName='navigation' iconName='phr' />}
                        />
                    )}
                    <MenuBox
                        to='/service'
                        tag='/service'
                        title={t('navigation:acitivites')}
                        icon={<IconConfigWithTheme pageName='navigation' iconName='service' />}
                    />
                </Navigation>
            </WorkplazeThemeHideElement>
        </>
    );
}

export default ClaimNotFoundV2;
