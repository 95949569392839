import styled from '@emotion/styled';
import React from 'react';
import Webcam from 'react-webcam';
import useWindowSize from 'hooks/useWindowSize';
import Button from 'components/common/button';
import { breakpoint } from 'helpers';
import { DEFAULT_BREAKPOINTS_SIZE } from 'constants/default-config';
import { POST } from 'api';
import { useNavigate } from 'react-router-dom';
import usePageContext from 'hooks/usePageContext';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

// Aspect Ratio 16:9 (1.777777777777778) (FULL HD)
const videoConstraints = {
    width: { min: 1280, ideal: 1920, max: 1920 },
    height: { min: 720, ideal: 1080 },
    aspectRatio: 1.777777777777778,
    frameRate: { max: 30 },
    facingMode: 'environment',
};
// Aspect Ratio 1:1 (1) (FULL HD)
const videoConstraintsMobile = {
    width: { min: 720, ideal: 1080, max: 1080 },
    height: { min: 720, ideal: 1080 },
    aspectRatio: 1,
    frameRate: { max: 30 },
    facingMode: 'environment',
};

const Grid = styled.div`
    label: webcam-frame;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: inherit;
    min-height: inherit;
    padding-top: 24px;

    ${breakpoint('XS')} {
        padding-top: 0;
    }

    .camera-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 500px;
        margin: auto;

        ${breakpoint('XS')} {
            width: 100%;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .id-card-guideline {
        --aspectRatio6_4: 1.5; // 6:4
        --width6_4: 400px;
        --height6_4: calc(var(--width6_4) / var(--aspectRatio6_4));

        ${breakpoint('XS')} {
            --width6_4: 300px;
        }

        position: absolute;
        top: calc(50% - (var(--height6_4) / 2));
        left: calc(50% - (var(--width6_4) / 2));
        width: var(--width6_4);
        height: var(--height6_4);
        z-index: 2;

        .grid-corner-top,
        .grid-corner-bottom {
            &-left {
                position: absolute;
                width: 40px;
                height: 40px;
                border: 4px solid #fff;
            }
            &-right {
                position: absolute;
                width: 40px;
                height: 40px;
                border: 4px solid #fff;
            }
        }

        .grid-corner-top-left {
            top: 0;
            left: 0;
            border-right: 0;
            border-bottom: 0;
        }
        .grid-corner-top-right {
            top: 0;
            right: 0;
            border-left: 0;
            border-bottom: 0;
        }
        .grid-corner-bottom-left {
            left: 0;
            bottom: 0;
            border-top: 0;
            border-right: 0;
        }
        .grid-corner-bottom-right {
            right: 0;
            bottom: 0;
            border-top: 0;
            border-left: 0;
        }
    }

    .image-frame {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        max-width: 550px;
        padding: 4px;
        margin: auto;

        ${breakpoint('XS')} {
            max-width: 500px;
        }
    }

    .camera-tools {
        width: 100%;
        padding: 24px;
        margin-top: 24px;
        background-color: #000000ae;

        .tools {
            display: flex;
            width: 100%;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            max-width: 500px;
        }

        ${breakpoint('XS')} {
            margin-top: 0;

            .tools {
                max-width: unset;
            }
        }
    }
`;

/**
 *
 * @param {{
 * servicePath: string
 * pid?: string|null
 * onFinished?: ()=> void
 * }} props
 * @returns
 */
function CameraThaiIdCard({ servicePath = '', onFinished, pid = undefined }) {
    const navigate = useNavigate();
    const { t } = useTranslation(['claim']);
    const { width } = useWindowSize();
    const { profile } = useAuth();
    const pageProps = usePageContext();
    //  ref
    const webcamRef = React.useRef(null);
    // state
    const [imageSrc, setImageSrc] = React.useState('loadData...');
    const [deviceId, setDeviceId] = React.useState(null);
    const [alreadyUserImage, setAlreadyUserImage] = React.useState(false);

    /**
     * Array<{
     * label: String,
     * deviceId: String
     * }>
     */
    const [devices, setDevices] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // method
    const onUseThisPicture = async () => {
        if (alreadyUserImage) {
            if (onFinished) {
                return onFinished();
            } else {
                return navigate(servicePath + '/claim/profile/face' + window.location.search);
            }
        }

        setIsLoading(true);
        try {
            await POST('/user/saveImage/idCard', {
                pid: pid ?? profile.userPid,
                data: imageSrc,
            });
            setIsLoading(false);

            if (onFinished) {
                onFinished();
            } else {
                navigate(servicePath + '/claim/profile/face' + window.location.search);
            }
        } catch (error) {
            console.error('/user/saveImage/idCard error', error);
            setIsLoading(false);
        }
    };

    const onCapture = React.useCallback(() => {
        const _imageSrc = getScreenshot();

        setImageSrc(_imageSrc);
    }, [webcamRef]);

    const getScreenshot = () => {
        const { state, props } = webcamRef.current;

        if (!state.hasUserMedia) return null;

        const canvas = getCanvas();
        // return canvas && canvas.toDataURL(props.screenshotFormat, props.screenshotQuality);
        return canvas && canvas.toDataURL('image/jpeg', 0.7);
    };

    const getCanvas = () => {
        const { state } = webcamRef.current;
        const aspectRatio = 1.5; // 6:4

        if (!webcamRef.current.video) return null;

        if (!state.hasUserMedia || !webcamRef.current.video.videoHeight) return null;

        if (!webcamRef.current.ctx) {
            const canvasWidth = webcamRef.current.video.videoWidth;
            const canvasHeight = canvasWidth / aspectRatio; // convert to 6:4
            webcamRef.current.canvas = document.createElement('canvas');
            webcamRef.current.canvas.width = canvasWidth;
            webcamRef.current.canvas.height = canvasHeight;
            webcamRef.current.ctx = webcamRef.current.canvas.getContext('2d');
        }

        const { ctx, canvas } = webcamRef.current;

        if (ctx && canvas) {
            let guideWidth = 400;

            if (width <= 580) {
                guideWidth = 300;
            }

            const sWidth = parseInt((guideWidth * webcamRef.current.video.videoWidth) / webcamRef.current.video.clientWidth);
            const sHeight = parseInt(sWidth / aspectRatio);
            const sx = webcamRef.current.video.videoWidth / 2 - sWidth / 2;
            const sy = parseInt(webcamRef.current.video.videoHeight / 2 - sHeight / 2);

            // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
            ctx.drawImage(
                webcamRef.current.video,
                sx,
                sy,
                sWidth,
                sHeight,
                0,
                0,
                webcamRef.current.video.videoWidth,
                webcamRef.current.video.videoWidth / 1.5
            );
        }

        return canvas;
    };

    const handleDevices = React.useCallback(
        (mediaDevices) => {
            setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
        },
        [setDevices]
    );

    const handleDeviceUsed = (stream) => {
        const tracks = stream.getTracks();

        if (tracks) {
            setDeviceId(tracks[0].getSettings().deviceId);
        }
    };

    React.useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    React.useEffect(() => {
        const checkUserIdCardImage = async () => {
            try {
                const res = await POST('/user/getImage/idCard', { pid: pid ?? profile.userPid });

                if (res.data) {
                    setImageSrc(res.data);
                    setAlreadyUserImage(true);
                } else {
                    setImageSrc('');
                }
                pageProps.setPageLoaded(true);
            } catch (error) {
                console.error('/user/getImage/idCard error:', error);
                setImageSrc('');
                pageProps.setPageLoaded(true);
            }
        };

        checkUserIdCardImage();
    }, []);

    return (
        <>
            {imageSrc ? (
                <Grid>
                    <div className='image-frame'>{imageSrc !== 'loadData...' && <img src={imageSrc} />}</div>
                    <div className='camera-tools'>
                        <div className='tools'>
                            <Button
                                onClick={() => {
                                    setImageSrc('');
                                    setAlreadyUserImage(false);
                                }}
                            >
                                <IconConfigWithTheme pageName='camera_thai_idcard' iconName='camera' />
                                &nbsp;{t('claim:pid.retake')}
                            </Button>
                            <Button
                                variant='primary'
                                disabled={!imageSrc || imageSrc === 'loadData...'}
                                className='ml-auto'
                                onClick={onUseThisPicture}
                            >
                                {t('claim:pid.confirm')}
                            </Button>
                        </div>
                    </div>
                </Grid>
            ) : (
                <Grid>
                    <div className='camera-frame'>
                        <div className='id-card-guideline'>
                            <span className='grid-corner-top-left' />
                            <span className='grid-corner-bottom-left' />
                            <span className='grid-corner-top-right' />
                            <span className='grid-corner-bottom-right' />
                        </div>

                        <Webcam
                            ref={webcamRef}
                            audio={false}
                            onUserMedia={handleDeviceUsed}
                            screenshotQuality={1}
                            screenshotFormat='image/png'
                            videoConstraints={
                                width <= DEFAULT_BREAKPOINTS_SIZE['XL']
                                    ? { ...videoConstraintsMobile, deviceId: deviceId }
                                    : { ...videoConstraints, deviceId: deviceId }
                            }
                        />
                    </div>

                    <div className='camera-tools'>
                        <div className='tools'>
                            <Button variant='primary' loading={isLoading} className='ml-auto' onClick={onCapture}>
                                <IconConfigWithTheme pageName='camera_thai_idcard' iconName='camera' /> {t('claim:pid.take_photo')}
                            </Button>
                        </div>
                    </div>
                </Grid>
            )}
        </>
    );
}

export default CameraThaiIdCard;
